<template>
    <div id="main">
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <div class="logo">
                <span v-if="!collapsed">Game Battle Lab</span>
            </div>
            <a-menu
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                mode="inline"
                theme="dark"
                :inline-collapsed="collapsed"
            >
                <a-menu-item key="home" @click="this.$router.push('/dashboard/home')">
                    <DashboardOutlined />
                    <span>Dashboard</span>
                </a-menu-item>
                <a-sub-menu key="rule">
                    <template #icon>
                        <BookOutlined />
                    </template>
                    <template #title>Rule</template>
                    <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="ruleHero" @click="this.$router.push('/rule/hero')">
                        <span>Hero</span>
                    </a-menu-item>
                    <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="rulePersonality">
                        <span>Hero Personality</span>
                    </a-menu-item>
                    <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="ruleType">
                        <span>Hero Type</span>
                    </a-menu-item>
                    <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="ruleAbility">
                        <span>Hero Ability</span>
                    </a-menu-item>
                    <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="ruleGame">
                        <span>Game</span>
                    </a-menu-item>
                </a-sub-menu>
                <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="hero" @click="this.$router.push('/hero/list')">
                    <UserOutlined />
                    <span>Hero</span>
                </a-menu-item>
                <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="team" @click="this.$router.push('/team/list')">
                    <TeamOutlined />
                    <span>Team</span>
                </a-menu-item>
                <a-menu-item v-if="currentUser.roles.includes('PLAYER')" key="game" @click="this.$router.push('/team/list')">
                    <StarOutlined />
                    <span>Game</span>
                </a-menu-item>
                <a-menu-item v-if="currentUser.roles.includes('ADMIN')" key="management">
                    <SettingOutlined />
                    <span>Management</span>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0; text-align:left;">
                <MenuUnfoldOutlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)"/>
                <MenuFoldOutlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>
                <div id="header-avatar-wrapper">
                    <avatar-dropdown id="header-avatar" :currentUser="currentUser"/>
                </div>
            </a-layout-header>
            <a-layout-content>
                <router-view />
            </a-layout-content>
            <a-layout-footer>Footer</a-layout-footer>
        </a-layout>
    </a-layout>
    </div>
</template>

<script>
import {
    DashboardOutlined,
    MenuFoldOutlined, 
    MenuUnfoldOutlined,
    BookOutlined,
    UserOutlined,
    TeamOutlined,
    StarOutlined,
    SettingOutlined,
    } from '@ant-design/icons-vue'
import AvatarDropdown from '@/component/Header/AvatarDropdown.vue'

export default {
    name: 'DashboardLayout',
    components: {
        AvatarDropdown,
        DashboardOutlined,
        MenuFoldOutlined,
        MenuUnfoldOutlined,
        BookOutlined,
        UserOutlined,
        TeamOutlined,
        StarOutlined,
        SettingOutlined,
    },
    data() {
        return {
            collapsed: false,
            selectedKeys: [this.$route.name],
            openKeys: [],
            preOpenKeys: [],
            currentUser: {
                id: this.$store.getters.userId,
                name: this.$store.getters.userName,
                image: this.$store.getters.userPicture,
                nick: this.$store.getters.userNick,
                roles: this.$store.getters.userRoles,
            }
        }
    },
    watch: {
        openKeys(val, oldVal) {
            this.preOpenKeys = oldVal;
        }
    },
    methods: {
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
            this.openKeys = this.collapsed? [] : this.preOpenKeys;
        }
    }
}
</script>

<style lang="less" scoped>
#main {
    height: 100%;
}

#main .trigger {
    font-size: 24px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#main .trigger:hover {
    color: #1890ff;
}

#main .logo {
    height: 32px;
    margin: 16px;
    color: white;
    font-size: 18px;
}

#header-avatar-wrapper {
    float: right;
}
</style>