import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
    if (to.meta.public) {
        next();
        return ;
    }
    if (!store.getters.userId) {
        store.dispatch('getUser')
        .then( () => {
            next('/');
        })
        .catch( () => {
            store.dispatch('logout');
            next('/user/login');
        });
    }
    else {
        next();
    }
});