<template>
    <a-dropdown placement="bottomRight">
        <span class="ant-pro-account-avatar">
            <a-avatar size="small" :src="currentUser.image" class="antd-pro-global-header-index-avatar"/>
            <span>{{ currentUser.name }}</span>
        </span>
        <template v-slot:overlay>
            <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
                <a-menu-item @click="handleToAccount">
                    <UserOutlined />
                    <span>Account</span>
                </a-menu-item>
                <a-menu-item @click="handleLogout">
                    <LogoutOutlined />
                    <span>Logout</span>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script>
import { UserOutlined, LogoutOutlined } from '@ant-design/icons-vue'
export default {
    name: 'AvatarDropdown',
    props: {
        currentUser: {
            type: Object,
            default: () => null
        }
    },
    components: {
        UserOutlined,
        LogoutOutlined,
    },
    methods: {
        handleToAccount() {
            this.$router.push({path: '/account/me' });
        },
        handleLogout() {
            this.$router.push({path: '/logout' });
        }
    }
}
</script>

<style lang="less" scoped>

</style>