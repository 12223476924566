import { BasicLayout, DashboardLayout, BlankLayout } from '@/layout'
import store from '@/store'

export const routerMap = [
    {
        path: '/',
        name: 'index',
        component: BasicLayout,
        redirect: '/dashboard/home'
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/home',
        component: DashboardLayout,
        children: [
            {
                path: '/dashboard/home',
                name: 'home',
                component: () => import('@/views/dashboard/Home.vue')
            }
        ]
    },
    {
        path: '/rule',
        name: 'rule',
        redirect: '/rule/hero',
        component: DashboardLayout,
        children: [
            {
                path: '/rule/hero',
                name: 'ruleHero',
                component: () => import('@/views/rule/Hero.vue')
            }
        ]
    },
    {
        path: '/hero',
        name: 'hero',
        redirect: '/hero/list',
        component: DashboardLayout,
        children: [
            {
                path: '/hero/list',
                name: 'heroList',
                component: () => import('@/views/hero/List.vue')
            }
        ]
    },
    {
        path: '/team',
        name: 'team',
        redirect: '/team/list',
        component: DashboardLayout,
        children: [
            {
                path: '/team/list',
                name: 'teamList',
                component: () => import('@/views/team/List.vue')
            }
        ]
    },
    {
        path: '/user',
        component: BasicLayout,
        redirect: '/user/login',
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login.vue')
            }
        ],
        meta : {
            public: true
        }
    },
    {
        path: '/logout',
        component: BlankLayout,
        beforeEnter: (to, from, next) => {
            store.dispatch('logout');
            next('/user/login');
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "error" */ '@/views/error/NotFound.vue'),
        meta: {
            public: true
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
        meta: {
            public: true
        }
    }
];