import axios from 'axios'
import store from '@/store'
import router from '@/router'

const toLogin = () => {
    router.replace({
        path: '/user/login'
    });
}

const errorHandler = (status, other) => {
    switch (status) {
        case 401:
            // no token
            toLogin();
            break;
        case 403:
            // token expired
            toLogin();
            break;
        case 500:
            // server error
            console.log('server error');
            break;
        default:
            console.log(other);
    }
}

let instance = axios.create({
    withCredentials: true,
    timeout: 1000 * 30
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
    config => {
        console.log('config axios');
        const token = store.getters.accessToken;
        token && (config.headers.Authorization = `Bearer ${token}`);
        return config;
    },
    error => Promise.error(error)
);

instance.interceptors.response.use(
    res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),

    error => {
        const { response } = error;
        if (response) {
            errorHandler(response.status, response.data.message);
            return Promise.reject(response);
        }
        else {
            // 断网
            if (!window.navigator.onLine) {
                return Promise.reject(error);
            }
            else {
                return Promise.reject(error);
            }
        }
    }
);

export default instance;
