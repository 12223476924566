import api from '@/api'
import storage from 'store'
import {
  STORAGE_KEY_USER_ID,
  STORAGE_KEY_USER_NAME,
  STORAGE_KEY_USER_PICTURE,
  STORAGE_KEY_USER_NICK,
  STORAGE_KEY_USER_ROLES,
} from '@/store/constant'

const user = {
    state: {
        id: '',
        name: '',
        picture: '',
        nick: '',
        roles: [],
    },
    mutations: {
        SET_ID: (state, id) => {
            state.id = id;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_PICTURE: (state, picture) => {
            state.picture = picture;
        },
        SET_NICK: (state, nick) => {
            state.nick = nick;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        }
    },
    actions: {
        loadUser({ commit }) {
            return new Promise((resolve) => {
                if (storage.get(STORAGE_KEY_USER_ID)) {
                    commit('SET_ID', storage.get(STORAGE_KEY_USER_ID));
                    commit('SET_NAME', storage.get(STORAGE_KEY_USER_NAME));
                    commit('SET_PICTURE', storage.get(STORAGE_KEY_USER_PICTURE));
                    commit('SET_NICK', storage.get(STORAGE_KEY_USER_NICK));
                    commit('SET_ROLES', storage.get(STORAGE_KEY_USER_ROLES));
                }
                resolve();
            });
        },
        getUser({ commit }) {
            return new Promise((resolve, reject) => {
                api.user.me().then(response => {
                    console.log('getUser successful');
                    const data = response.data;
                    commit('SET_ID', data.id);
                    commit('SET_NAME', data.name);
                    commit('SET_PICTURE', data.picture);
                    commit('SET_NICK', data.nick);
                    commit('SET_ROLES', data.roles);
                    storage.set(STORAGE_KEY_USER_ID, data.id);
                    storage.set(STORAGE_KEY_USER_NAME, data.name);
                    storage.set(STORAGE_KEY_USER_PICTURE, data.picture);
                    storage.set(STORAGE_KEY_USER_NICK, data.nick);
                    storage.set(STORAGE_KEY_USER_ROLES, data.roles);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getUser error');
                    reject(error);
                })
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                api.auth.logout().then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve(error);
                })
                commit('SET_ID', '');
                commit('SET_NAME', '');
                commit('SET_PICTURE', '');
                commit('SET_NICK', '');
                commit('SET_ROLES', '');
                storage.remove(STORAGE_KEY_USER_ID);
                storage.remove(STORAGE_KEY_USER_NAME);
                storage.remove(STORAGE_KEY_USER_PICTURE);
                storage.remove(STORAGE_KEY_USER_NICK);
                storage.remove(STORAGE_KEY_USER_ROLES);
                resolve();
            })
        }
    }
}

export default user